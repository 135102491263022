import React, { FC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import GridLayout from '@sport1/news-styleguide/GridLayout'
import TeaserList from '../list'
import { ItemCardProps } from '@/components/TeaserCard'
import { TeaserSizeTypes } from '@/types/teaser'
import { SizeTypes } from '@/types/size'
import Breakpoint from '@/utils/breakpoints/Breakpoint'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'
import { isCardTeaser } from '@/components/TeaserCard/util/isCardTeaser'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

const NineItemCardContent: FC<ItemCardProps> = ({
    title,
    lazy,
    hasRightColumn,
    teaser,
    getTeaserComponent,
    displaySizes = defaultDisplaySizes,
}) => {
    if (teaser.length < 9) return null

    const [isMobileCardTeaser, isTabletCardTeaser, isDesktopCardTeaser] = isCardTeaser(
        displaySizes,
        hasRightColumn
    )

    return (
        <>
            <Breakpoint lazy={lazy} device={[SizeTypes.TABLET, SizeTypes.DESKTOP]}>
                <NonFlexingContainer display={['none', 'none', 'flex']}>
                    <NonFlexingContainer
                        marginBottom={
                            hasRightColumn
                                ? ['spacing-none', 'spacing-none', 'spacing-6']
                                : ['spacing-none', 'spacing-none', 'spacing-6', 'spacing-none']
                        }
                    >
                        {getTeaserComponent({
                            index: 0,
                            key: `${title}_${0}_desktop`,
                            type: hasRightColumn
                                ? TeaserSizeTypes.BIG
                                : TeaserSizeTypes.HORIZONTAL_BIG,
                            isCardTeaser: !isMobileCardTeaser,
                            includeAdsForSizes: {
                                isDesktop: true,
                                isTablet: true,
                                isMobile: false,
                            },
                        })}
                    </NonFlexingContainer>
                    <Breakpoint lazy={lazy} device={hasRightColumn ? undefined : SizeTypes.DESKTOP}>
                        <NonFlexingContainer
                            display={hasRightColumn ? 'none' : ['none', 'none', 'none', 'flex']}
                            testID="nine-teaser-card-desktop-container"
                        >
                            <GridLayout
                                columnType="33-33-33"
                                firstColumn={getTeaserComponent({
                                    index: 1,
                                    key: `${title}_${1}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 2,
                                    key: `${title}_${2}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                thirdColumn={getTeaserComponent({
                                    index: 3,
                                    key: `${title}_${3}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                spaceBetween="standard"
                                spaceY="standard"
                            />
                            <GridLayout
                                columnType="33-33-33"
                                firstColumn={getTeaserComponent({
                                    index: 4,
                                    key: `${title}_${4}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 5,
                                    key: `${title}_${5}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                thirdColumn={getTeaserComponent({
                                    index: 6,
                                    key: `${title}_${6}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                spaceBetween="standard"
                            />
                            <GridLayout
                                columnType="33-33-33"
                                firstColumn={getTeaserComponent({
                                    index: 7,
                                    key: `${title}_${7}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 8,
                                    key: `${title}_${8}_desktop`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isDesktopCardTeaser,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: false,
                                        isMobile: false,
                                    },
                                })}
                                thirdColumn={<div />}
                                spaceY="standard"
                                spaceBetween="standard"
                            />
                        </NonFlexingContainer>
                    </Breakpoint>
                    <Breakpoint
                        lazy={lazy}
                        device={
                            hasRightColumn
                                ? [SizeTypes.TABLET, SizeTypes.DESKTOP]
                                : SizeTypes.TABLET
                        }
                    >
                        <NonFlexingContainer
                            display={
                                hasRightColumn
                                    ? ['none', 'none', 'flex']
                                    : ['none', 'none', 'flex', 'none']
                            }
                            testID="nine-teaser-card-tablet-container"
                        >
                            <GridLayout
                                columnType="50-50"
                                firstColumn={getTeaserComponent({
                                    index: 1,
                                    key: `${title}_${1}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 2,
                                    key: `${title}_${2}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                spaceBetween="standard"
                            />
                            <GridLayout
                                columnType="50-50"
                                firstColumn={getTeaserComponent({
                                    index: 3,
                                    key: `${title}_${3}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 4,
                                    key: `${title}_${4}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                spaceBetween="standard"
                                spaceY="standard"
                            />
                            <GridLayout
                                columnType="50-50"
                                firstColumn={getTeaserComponent({
                                    index: 5,
                                    key: `${title}_${5}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 6,
                                    key: `${title}_${6}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                spaceBetween="standard"
                            />
                            <GridLayout
                                columnType="50-50"
                                firstColumn={getTeaserComponent({
                                    index: 7,
                                    key: `${title}_${7}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                secondColumn={getTeaserComponent({
                                    index: 8,
                                    key: `${title}_${8}_tablet`,
                                    type: TeaserSizeTypes.MID,
                                    isCardTeaser: isTabletCardTeaser,
                                    includeAdsForSizes: hasRightColumn
                                        ? { isDesktop: true, isTablet: true, isMobile: false }
                                        : { isDesktop: false, isTablet: true, isMobile: false },
                                })}
                                spaceY="standard"
                                spaceBetween="standard"
                            />
                        </NonFlexingContainer>
                    </Breakpoint>
                </NonFlexingContainer>
            </Breakpoint>
            <Breakpoint lazy={lazy} device={SizeTypes.MOBILE}>
                <TeaserList
                    title={title}
                    isCardTeaser={isMobileCardTeaser}
                    display={['flex', 'flex', 'none']}
                    teaser={teaser}
                    getTeaserComponent={props =>
                        getTeaserComponent({
                            ...props,
                            includeAdsForSizes: {
                                isDesktop: false,
                                isTablet: false,
                                isMobile: true,
                            },
                        })
                    }
                />
            </Breakpoint>
        </>
    )
}

export default memo(NineItemCardContent)
