import React, { FC, memo } from 'react'
import GridLayout from '@sport1/news-styleguide/GridLayout'
import { ItemCardProps } from '..'
import TeaserList from '@/components/TeaserCard/list'
import { TeaserSizeTypes } from '@/types/teaser'
import { SizeTypes } from '@/types/size'
import Breakpoint from '@/utils/breakpoints/Breakpoint'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

const TwoItemCardContent: FC<ItemCardProps> = ({
    title,
    lazy,
    teaser,
    getTeaserComponent,
    displaySizes = defaultDisplaySizes,
    hasRightColumn,
}) => {
    if (teaser.length < 2) return null
    const { isMobile, isTablet, isDesktop } = displaySizes
    return (
        <>
            <Breakpoint lazy={lazy} device={SizeTypes.DESKTOP}>
                <GridLayout
                    display={['none', 'none', 'none', 'flex']}
                    columnType="50-50"
                    firstColumn={getTeaserComponent({
                        index: 0,
                        key: `${title}_${0}_desktop`,
                        type: hasRightColumn ? TeaserSizeTypes.MID : TeaserSizeTypes.BIG,
                        isCardTeaser: isDesktop,
                        includeAdsForSizes: { isDesktop: true, isTablet: false, isMobile: false },
                    })}
                    secondColumn={getTeaserComponent({
                        index: 1,
                        key: `${title}_${1}_desktop`,
                        type: hasRightColumn ? TeaserSizeTypes.MID : TeaserSizeTypes.BIG,
                        isCardTeaser: isDesktop,
                        includeAdsForSizes: { isDesktop: true, isTablet: false, isMobile: false },
                    })}
                    spaceBetween="standard"
                    testID="two-teaser-card-desktop-grid"
                />
            </Breakpoint>
            <Breakpoint lazy={lazy} device={SizeTypes.TABLET}>
                <GridLayout
                    display={['none', 'none', 'flex', 'none']}
                    columnType="50-50"
                    firstColumn={getTeaserComponent({
                        index: 0,
                        key: `${title}_${0}_tablet`,
                        type: TeaserSizeTypes.MID,
                        isCardTeaser: isTablet,
                        includeAdsForSizes: { isDesktop: false, isTablet: true, isMobile: false },
                    })}
                    secondColumn={getTeaserComponent({
                        index: 1,
                        key: `${title}_${1}_tablet`,
                        type: TeaserSizeTypes.MID,
                        isCardTeaser: isTablet,
                        includeAdsForSizes: { isDesktop: false, isTablet: true, isMobile: false },
                    })}
                    spaceBetween="standard"
                    testID="two-teaser-card-tablet-grid"
                />
            </Breakpoint>
            <Breakpoint lazy={lazy} device={SizeTypes.MOBILE}>
                <TeaserList
                    title={title}
                    isCardTeaser={isMobile}
                    display={['flex', 'flex', 'none']}
                    teaser={teaser}
                    getTeaserComponent={props =>
                        getTeaserComponent({
                            ...props,
                            includeAdsForSizes: {
                                isDesktop: false,
                                isTablet: false,
                                isMobile: true,
                            },
                        })
                    }
                />
            </Breakpoint>
        </>
    )
}

export default memo(TwoItemCardContent)
