import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { DisplayProp } from '@sport1/news-styleguide/Types'
import { TeaserSizeTypes } from '@/types/teaser'
import { ItemCardProps } from '@/components/TeaserCard'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

type CardProps = ItemCardProps & {
    display?: DisplayProp['display']
    isCardTeaser?: boolean
}

const TeaserList: FC<CardProps> = ({
    title,
    teaser,
    isCardTeaser = false,
    display,
    getTeaserComponent,
}) => (
    <NonFlexingContainer key={teaser[0].id} display={display} testID="teaser-card-list">
        {teaser.map((teaserEntry, index) => {
            if (index === 0) {
                return (
                    <NonFlexingContainer
                        key={`teaser-first-item-${teaserEntry.id}`}
                        marginBottom="spacing-6"
                    >
                        {getTeaserComponent({
                            index,
                            key: `${title}_${teaserEntry.id}_mobile`,
                            type: TeaserSizeTypes.BIG,
                            isCardTeaser,
                            includeAdsForSizes: { isDesktop: true, isTablet: true, isMobile: true },
                        })}
                    </NonFlexingContainer>
                )
            }

            const isLastItem = index === teaser.length - 1

            return (
                <NonFlexingContainer key={`teaser-list-item-${teaserEntry.id}-${index}`}>
                    <NonFlexingContainer marginBottom={!isLastItem ? 'spacing-6' : 'spacing-none'}>
                        {getTeaserComponent({
                            index,
                            key: `${title}_${index}_mobile`,
                            type: TeaserSizeTypes.LIST,
                            isCardTeaser,
                            includeAdsForSizes: { isDesktop: true, isTablet: true, isMobile: true },
                        })}
                    </NonFlexingContainer>
                </NonFlexingContainer>
            )
        })}
    </NonFlexingContainer>
)

export default TeaserList
