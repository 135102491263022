import { DisplaySize } from '@/hooks/useBreakpointDisplaySize'
import { SizeTypes } from '@/types/size'

const displaySizeToBoolean = (breakpointDisplaySize: SizeTypes, displaySizes: DisplaySize) => {
    if (breakpointDisplaySize === SizeTypes.MOBILE) {
        return displaySizes.isMobile
    } else if (breakpointDisplaySize === SizeTypes.TABLET) {
        return displaySizes.isTablet
    }
    return displaySizes.isDesktop
}

export const isCardTeaser = (displaySizes: DisplaySize, hasRightColumn?: boolean): boolean[] => {
    return [SizeTypes.MOBILE, SizeTypes.TABLET, SizeTypes.DESKTOP].map(size => {
        const { isTablet, isDesktop } = displaySizes
        if (hasRightColumn === undefined || size === SizeTypes.MOBILE) {
            return displaySizeToBoolean(size, displaySizes)
        } else if (size === SizeTypes.TABLET) {
            return hasRightColumn ? isDesktop || isTablet : isTablet
        }
        return hasRightColumn ? false : isDesktop
    })
}
