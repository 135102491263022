import React, { FC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { TeaserSizeTypes } from '@/types/teaser'
import { ItemCardProps } from '@/components/TeaserCard'
import Breakpoint from '@/utils/breakpoints/Breakpoint'
import { SizeTypes } from '@/types/size'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'

const OneItemCardContent: FC<
    Pick<ItemCardProps, 'title' | 'getTeaserComponent' | 'lazy' | 'hasRightColumn' | 'displaySizes'>
> = ({ title, lazy, hasRightColumn, getTeaserComponent, displaySizes = defaultDisplaySizes }) => {
    const { isMobile } = displaySizes

    return (
        <>
            <Breakpoint lazy={lazy} device={[SizeTypes.TABLET, SizeTypes.DESKTOP]}>
                <NonFlexingContainer
                    display={['none', 'none', 'flex']}
                    key="one-desktop"
                    testID="one-teaser-card-desktop-container"
                >
                    {getTeaserComponent({
                        index: 0,
                        key: `${title}_${0}_desktop`,
                        type: hasRightColumn ? TeaserSizeTypes.BIG : TeaserSizeTypes.HORIZONTAL_BIG,
                        isCardTeaser: !isMobile,
                        includeAdsForSizes: { isDesktop: true, isTablet: true, isMobile: false },
                    })}
                </NonFlexingContainer>
            </Breakpoint>
            <Breakpoint lazy={lazy} device={SizeTypes.MOBILE}>
                <NonFlexingContainer
                    display={['flex', 'flex', 'none']}
                    key="one-mobile"
                    testID="one-teaser-card-mobile-container"
                >
                    {getTeaserComponent({
                        index: 0,
                        key: `${title}_${0}_mobile`,
                        type: TeaserSizeTypes.BIG,
                        isCardTeaser: isMobile,
                        includeAdsForSizes: { isDesktop: false, isTablet: false, isMobile: true },
                    })}
                </NonFlexingContainer>
            </Breakpoint>
        </>
    )
}

export default memo(OneItemCardContent)
